<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>个人信息</span>
      </div>
      <img
        src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
        class="image"
      />
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ this.userInfo.userName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            邮箱
          </template>
          {{ this.userInfo.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            姓名
          </template>
          {{ this.userInfo.realName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{ this.userInfo.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-alarm-clock"></i>
            最后一次登录时间
          </template>
          {{ this.userInfo.lastLoginTime | formateDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            登录IP
          </template>
          {{ this.userInfo.clientIp }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>修改密码</span>
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
       <el-form-item label="原密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="ruleForm.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
    
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
//时间格式转换
import { formateDate } from '../../utils/date.js'
//导入MD5加密算法
import md5 from 'js-md5'
let Base64 = require('js-base64').Base64
export default {
  //时间戳转指定格式的日期
  filters: {
    formateDate(time) {
      let date = new Date(time)
      return formateDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  data() {
      //自定义校验
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
         //验证通过
        callback()
      }
    }
    //自定义校验
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      //获取当前的用户
      userInfo: {},
      //密码修改表单
      ruleForm: {
        checkPass:'',
        password: '',
        oldPassword: '',
      },
      rules: {
          //validator指定自定义的校验规则
        oldPassword: [{ validator: validatePass, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        //validator指定自定义的校验规则
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
    }
  },
  methods: {
      //提交修改密码表单
    submitForm(formName) {
        //表单校验
      this.$refs[formName].validate(async  (valid) => {
          //校验不通过
       if (!valid) return
       //发起修改用户密码的数据请求
        //对密码进行加密
        this.ruleForm.password = Base64.encode(md5(this.ruleForm.password))
        this.ruleForm.oldPassword = Base64.encode(md5(this.ruleForm.oldPassword))
        this.ruleForm.checkPass = Base64.encode(md5(this.ruleForm.checkPass))
        const { data: res } = await this.$http.put(
          '/admin/user/changePassword',
          this.ruleForm
        )
        if(res.code !== 200){
            //重置表单
            this.resetForm(formName)
            return this.$message.error(res.msg)
           
        }
        //重置表单
        this.resetForm(formName)
        //提示修改成功
        this.$message.success('更新密码成功！')
         //2.通过编程式导航跳转到后台主页，路由地址是 /home
        this.$router.push('/login')
        
      })
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
  created() {
    //JSON.parse()json转对象
    this.userInfo = JSON.parse(window.sessionStorage.getItem('user'))
  },
}
</script>

<style lang="less" scoped>
.image {
  float: left;
  height: 180px;
  border-radius: 50%;
}
.margin-top {
  display: flow-root;
  padding-left: 20px;
}
</style>