<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <!-- border边框线  stripe创建带斑马纹的表格  type="index"索引列-->
      <el-table :data="rightsList" border stripe>
        <el-table-column type="index">
              <template slot-scope="scope">
            <!-- scope.$index当前这一行的索引0，1，2，3，4，5 -->
            {{ (queryInfo.page - 1) * queryInfo.limit + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="权限名称" prop="name" />
        <el-table-column label="路径" prop="url" />
        <el-table-column label="权限等级" prop="level">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 -->
          <template slot-scope="scope">
            <el-tag v-if="scope.row.level === 0">一级权限</el-tag>
            <el-tag type="success" v-else-if="scope.row.level === 1"
              >二级权限</el-tag
            >
            <el-tag type="warning" v-else-if="scope.row.level === 2"
              >三级权限</el-tag
            >
          </template>
        </el-table-column>
      </el-table>


        <!-- 分页区域 -->
      <!--  changeCurrentPage()不能加括号，应该下面函数有参数，这里不给值默认会传undefined -->
      <!-- 
         @size-change 监听 pagesize改变的事件 （选择显示多少条数据）
         @current-change  currentPage当前页数 改变时会触发 下一页
          current-page当前页数
          page-sizes 每页显示个数选择器的选项设置
          total总条目数 page-size每页显示条目个数默认10 prev上一页 pager页数 next下一页
          sizes选择器
         -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //权限列表
      rightsList: [],
      //获取功能列表的参数对象
      queryInfo: {
        name: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 5,
      },
       //总记录数
      total: 0,
    }
  },

  created() {
    //获取所有的权限
    this.getRightsList()
  },

  methods: {
    //获取权限列表
    async getRightsList() {
      const { data: res } = await this.$http.get('admin/function/list', {
        params: this.queryInfo,
      })
      if (res.code !== 200) {
        return this.$message.error('获取权限列表失败！')
      }
      this.rightsList = res.data.data
         //总记录数
      this.total = res.data.total
    //   console.log(this.rightsList)
    },

       // 监听 pagesize改变的事件（选择显示多少条数据）
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize
      this.getRightsList()
    },
    //监听 页码值下一页 改变的事件
    handleCurrentChange(newPage) {
      // console.log(newPage)
      this.queryInfo.page = newPage
      this.getRightsList()
    },
  },
}
</script>