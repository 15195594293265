<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>应用管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col :span="2">
          <el-button type="primary" @click="showAddDialog"
            ><i class="el-icon-plus"></i>新增</el-button
          >
        </el-col>
        <!-- clearable 得到一个可清空的输入框 -->
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.name"
            clearable
            @clear="getAppList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getAppList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="default" @click="resetData()">清空</el-button>
        </el-col>
      </el-row>

        <!-- 表格 -->
    <!-- 卡片视图 -->
    <!-- border边框线  stripe创建带斑马纹的表格  type="index"索引列-->
    <el-table :data="appList" border stripe>
      <el-table-column type="index">
        <template slot-scope="scope">
          <!-- scope.$index当前这一行的索引0，1，2，3，4，5 -->
          {{ (queryInfo.page - 1) * queryInfo.limit + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="应用名称" prop="name" />
      <el-table-column label="标识" prop="code" />
      <el-table-column label="生效开始时间" prop="startDate" />
      <el-table-column label="生效结束时间" prop="endDate" />
      <el-table-column label="是否禁用" prop="isDisabled">
        <template slot-scope="scope">
          <i
            class="el-icon-success"
            v-if="scope.row.isDisabled === false"
            style="color: lightgreen"
          ></i>
          <i class="el-icon-error" v-else style="color: red"></i>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 
          :disabled="scope.row.isSadmin" 如果未超级管理员则禁用所有操作-->
        <template slot-scope="scope">
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="showEditDialog(scope.row.id)"
            :disabled="scope.row.isSadmin"
            >编辑</el-button
          >
           <!-- round 圆形按钮 -->
          <el-button
            round
            size="mini"
            type="danger"
            icon="el-icon-delete"
            :disabled="scope.row.isSadmin"
            @click="showDeleteDialog(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

     <!-- 分页区域 -->
      <!--  changeCurrentPage()不能加括号，应该下面函数有参数，这里不给值默认会传undefined -->
      <!-- 
         @size-change 监听 pagesize改变的事件 （选择显示多少条数据）
         @current-change  currentPage当前页数 改变时会触发 下一页
          current-page当前页数
          page-sizes 每页显示个数选择器的选项设置
          total总条目数 page-size每页显示条目个数默认10 prev上一页 pager页数 next下一页
          sizes选择器
         -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

    </el-card>

  

    <!-- 添加应用的对话框 -->
    <el-dialog
      title="添加应用"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 
      addFunForm添加表单里面的数据一定要写全，不然重置表单会有些无法重置 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功, disabled禁止修改-->
        <el-form-item label="应用名称:" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="生效开始时间:" prop="startDate">
          <el-date-picker v-model="addForm.startDate" type="date" placeholder="选择日期"  value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="生效结束时间:" prop="endDate">
          <el-date-picker v-model="addForm.endDate" type="date" placeholder="选择日期"  value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="排序" prop="orderId">
          <el-input-number v-model="addForm.orderId" :min="0" />
        </el-form-item>
        <el-form-item label="是否禁用" prop="isDisabled">
          <el-switch v-model="addForm.isDisabled"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addApp">确 定</el-button>
      </span>
    </el-dialog>



      <!-- 修改应用的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="修改应用"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 -->
      <el-form
        ref="editFormRef"
        :model="editForm"
        label-width="120px"
        :rules="editFormRules"
      >
        <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功, disabled禁止修改-->
        <el-form-item label="应用名称:" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="生效开始时间:" prop="startDate">
          <el-date-picker v-model="editForm.startDate" type="date" placeholder="选择日期"  value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="生效结束时间:" prop="endDate">
          <el-date-picker v-model="editForm.endDate" type="date" placeholder="选择日期"  value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="排序" prop="orderId">
          <el-input-number v-model="editForm.orderId" :min="0" />
        </el-form-item>
        <el-form-item label="是否禁用" prop="isDisabled">
          <el-switch v-model="editForm.isDisabled"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editApp">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      //获取功能列表的参数对象
      queryInfo: {
        name: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 5,
      },
      //总记录数
      total: 0,
      //添加应用对话框显示
      addDialogVisible: false,
      //控制修改应用对话框的显示与隐藏
      editDialogVisible: false,
      //添加表单
      addForm: {},
      //应用列表
      appList: [],
      //添加应用表单的验证规则对象
      addFormRules: {
        name: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ],
      },

      //查询到的应用对象
      editForm: {},
      editFormRules: {
        name: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.getAppList()
  },
  methods: {
    //获取功能数据
    async getAppList() {
      const { data: res } = await this.$http.get('admin/app/list', {
        params: this.queryInfo,
      })

      if (res.code !== 200) {
        return this.$message.error('获取应用列表失败！')
      }
      // console.log(res.data)
      //把数据列表赋值给catelist
      this.appList = res.data.row
      //总记录数
      this.total = res.data.total
    },

    //添加按钮，展示添加分类的对话框
    showAddDialog() {
      //展示出对话框
      this.addDialogVisible = true
    },

    //监听应用功能对话框的关闭事件
    addDialogClosed() {
      //   console.log(this.addForm)
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.addFormRef.resetFields()
    },

    //添加应用
    addApp() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/admin/app/add',
          this.addForm
        )
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success('添加应用成功！')
        //隐藏添加应用的对话框
        this.addDialogVisible = false
        //重新获取应用列表数据
        this.getAppList()
        // console.log(this.addFunForm)
      })
    },


    //根据应用id删除应用
    async showDeleteDialog(id) {
      //弹框询问应用是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该应用, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      //如果应用确认删除，则返回值为字符串confirm
      //如果应用取消了删除，则返回值为字符串 cancel
      //  console.log(res)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/app/delete/' + id)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success('删除成功！')
      //刷新列表
      this.getAppList()
    },

     //展示编辑应用的对话框
    async showEditDialog(id) {
      // console.log(id)t
      const { data: res } = await this.$http.get('admin/app/' + id)
      if (res.code !== 200) {
        return this.$message.error('查询应用信息失败')
      }
      //赋值给编辑用户对象
      this.editForm = res.data.data
      //点击编辑按钮打开对话框
      this.editDialogVisible = true
    },

     //监听修改应用对话框的关闭事件
    editDialogClosed() {
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.editFormRef.resetFields()
    },

    //修改应用信息并提交
    editApp() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        //发起修改应用信息的数据请求
        const { data: res } = await this.$http.put(
          '/admin/app/update',
          this.editForm
        )
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        //关闭对话框
        this.editDialogVisible = false
        //刷新数据列表
        this.getAppList()
        //提示修改成功
        this.$message.success('更新应用信息成功！')
      })
    },


     // 监听 pagesize改变的事件（选择显示多少条数据）
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize
      this.getAppList()
    },
    //监听 页码值下一页 改变的事件
    handleCurrentChange(newPage) {
      // console.log(newPage)
      this.queryInfo.page = newPage
      this.getAppList()
    },

      // 重置表单
    resetData() {
      this.queryInfo = {
        name: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 5,
      }
      this.getAppList()
    },


  },
}
</script>

<style lang="less" scoped>
</style>