import Vue from 'vue'
//Message导入弹框提示组件
import {Button,Form,FormItem,Input,Message,
    Container,Header,Aside,Main,Menu,Submenu,
    MenuItemGroup,MenuItem,Breadcrumb,BreadcrumbItem,
    Card,Row,Col,Table,TableColumn,Switch,Tooltip,
    Pagination,Dialog,MessageBox,Tag,Tree,CheckboxGroup,
    Checkbox,InputNumber,Autocomplete,DatePicker,Select,Option,
    Cascader,Alert,Dropdown,DropdownMenu,DropdownItem,Descriptions,
    DescriptionsItem,RadioGroup,Radio} from 'element-ui'


Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(Autocomplete)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(RadioGroup)
Vue.use(Radio)
//全局定义， 通过this.$message进行调用
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm