<template>
  <div class="login_container">
    <!-- 头部 -->
    <div class="header">
      <div class="header-center fl">
        <div class="header-title">权限管理平台</div>
        <div class="header-img"></div>
      </div>
      <div class="header-bottom fl"></div>
    </div>

    <!-- 中间内容 -->
    <div class="content">
      <!--左侧动画  -->
      <div class="content-left">
        <!--<img src="images/d.png" alt="">-->
      </div>
      <!-- 登录表单 -->
      <div class="content-right">
        <div class="right-infp">
          <div class="login_box">
            <el-form
              ref="loginFormRef"
              :model="loginForm"
              :rules="loginFormRules"
              label-width="0px"
              class="login_form"
            >
              <!-- 用户名 -->
              <el-form-item prop="username">
                <el-input
                  v-model="loginForm.username"
                  prefix-icon="iconfont icon-user"
                ></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item prop="password">
                <el-input
                  v-model="loginForm.password"
                  prefix-icon="iconfont icon-3702mima"
                  type="password"
                ></el-input>
              </el-form-item>
              <!-- 按钮区域 -->
              <el-form-item class="btns">
                <el-button class="btnsColor" type="primary" @click="login"
                  >登录</el-button
                >
                <el-button class="btnsColor" type="info" @click="loginFormRef"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- 头像区域 -->

    <!-- 登录表单区域 -->
    <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值-->
  </div>
</template>

<script>
//导入MD5加密算法
import md5 from 'js-md5'
let Base64 = require('js-base64').Base64

export default {
  data() {
    return {
      //这个是登录表单的数据绑定对象
      loginForm: {
        grant_type: 'password',
        username: '',
        password: '',
        client_id: 'mayikt',
        client_secret: '123456',
      },
      //这是表单的验证规则对象
      loginFormRules: {
        //验证用户名是否合法
        username: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        //验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    //点击重置按钮，重置登录表单
    loginFormRef() {
      // console.log(this)
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.loginFormRef.resetFields()
    },

    // 使用同步调用 async  await等待当前执行完   如果不设置的话 response会没有结果，因为下面response的可能会比调用api还要快
    login() {
      //validate()对整个表单进行校验的方法，参数为一个回调函数。该回调函数会在校验结束后被调用，
      //并传入两个参数：是否校验成功和未通过校验的字段。若不传入回调函数，则会返回一个 promise
      this.$refs.loginFormRef.validate(async (valid) => {
        //valid第一个参数返回一个布尔值，验证表单是否通过
        if (!valid) return
        //对密码进行加密
        this.loginForm.password = Base64.encode(md5(this.loginForm.password))
        //获取返回结果的data字段里数据
        const data = await this.$http.get('oauth/token', {
          params: this.loginForm,
        })
        // console.log(data)
        if (data.data.code !== 200) {
          //重置表单
          this.loginFormRef()
          return this.$message.error(data.data.msg)
        }
        this.$message.success('登录成功')
        this.updateTime(data.data.id)
        //1.将登录成功之后的token，保存到客户端的sessionStorage中
        //1.1 项目中除了登录之外的其他API接口，必须在登录之后才能访问
        //1.2 token只应在当前网站打开期间生效，所有将token保存在客户端的sessionStorage中
        window.sessionStorage.setItem(
          'token',
          'Bearer ' + data.data.access_token
        )
        //JSON.stringify() 对象转json字符串
        window.sessionStorage.setItem(
          'user',JSON.stringify(data.data)
        )
        //2.通过编程式导航跳转到后台主页，路由地址是 /home
        this.$router.push('/home')
      })
    },

    //更新用户最后一次登录时间
    async updateTime(id) {
      //获取返回值的中data数据
      const { data: res } = await this.$http.get(`admin/user/updateLastloginTime/${id}`)
        // console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
    },
  },
}
</script>

<style lang="less" scoped>
/*头部*/
.header {
  width: 100%;
  height: 1.5rem;
  /*background: red;*/
}

.header .header-center {
  width: 70%;
  height: 85px;
  position: relative;
  margin: 0 auto;
  top: 30px;
}
.header .header-center .header-title {
  text-align: center;
  color: #ffffff;
  font-size: 1.4rem;
  text-shadow: 0 0 0.3rem #00d8ff;
  line-height: 1.05rem;
}
.header .header-img {
  background: url('../assets/head.gif') no-repeat center center;
  background-size: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0.8rem;
}

.content {
  width: 1000px;
  height: 7rem;
  /*background: green;*/
  margin: 1rem auto 0 auto;
  /*border: 1px solid green;*/
}
.content .content-left {
  width: 407px;
  height: 407px;
  /*border: 1px solid red;*/
  background: url('../assets/d.png') no-repeat;
  background-size: 100% 100%;
  padding: 0.4rem 0;
  box-sizing: border-box;
  margin-right: 2%;
  float: left;
  margin-top:100px
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.content .content-left {
  -webkit-transform: rotate(360deg);
  animation: rotation 15s linear infinite;
  -moz-animation: rotation 15s linear infinite;
  -webkit-animation: rotation 15s linear infinite;
  -o-animation: rotation 15s linear infinite;
}

.content .content-right {
  width: 407px;
  height: 407px;
  background: url('../assets/e.png') no-repeat;
  background-size: 100% 100%;
  float: right;
  position: relative;
  margin-top:100px
}
.right-infp {
  width: 4rem;
  height: 3rem;
  margin: auto auto;
  /* border: 1px solid red;*/
  vertical-align: center;
  position: absolute;
  top: 1.6rem;
  left: 0.9rem;
}

.el-input {
  background: url(../assets/b.png) no-repeat;
  background-size: 100% 100%;
}
.el-input /deep/ .el-input__inner {
  background: none !important;
  border: none;
}
.btnsColor {
  background-color: #00eaff;
  border-color: #00eaff;
}

.login_container {
  background-image: url(../assets/bj.jpg);
  height: 100%;
}

.login_form {
  position: absolute;
  top: 120px;
  width: 330px;
  padding: 0 20px;
  margin-left: 22px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
