<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <!-- 添加角色按钮区域 -->
      <el-row>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisbile = true"
            ><i class="el-icon-plus"></i>新增</el-button
          >
        </el-col>

        <!-- clearable 得到一个可清空的输入框 -->
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.roleName"
            clearable
            @clear="getRolesList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getRolesList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="default" @click="resetData()">清空</el-button>
        </el-col>
      </el-row>

      <!-- 角色列表区域 -->
      <!-- border边框线  stripe创建带斑马纹的表格  type="index"索引列-->
      <el-table :data="rolelist" border stripe>
        <!-- 展开列 
        展开行可访问的属性与使用自定义列模板时的 Scoped slot 相同-->
        <el-table-column type="expand">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 -->
          <template slot-scope="scope">
            <!-- :class给每一项都加上一个class = "bdbottom"的样式，并判断索引为0的时候加上一个bdtop属性样式
                   v-for用的时候必须要指定：key 不然会报错，以及不用的参数值也会报错 -->
            <el-row
              :class="['bdbottom', i1 === 0 ? 'bdtop' : '', 'vcenter']"
              v-for="(item1, i1) in scope.row.children"
              :key="item1.id"
            >
              <!-- 渲染一级权限 -->
              <el-col :span="5">
                <!-- closable 属性可以定义一个标签是否可移除 -->
                <el-tag closable @close="removeRightById(scope.row, item1.id)">
                  {{ item1.name }}</el-tag
                >
                <i class="el-icon-caret-right"></i>
              </el-col>
              <!-- 渲染二级权限和三级权限 -->
              <el-col :span="19">
                <!-- 通过for循环 嵌套渲染二级权限 
                        :class给每一项都加上一个class = "bdtop"的样式，
                        v-for用的时候必须要指定：key 不然会报错，以及不用的参数值也会报错 -->
                <el-row
                  :class="[i2 === 0 ? '' : 'bdtop', 'vcenter']"
                  v-for="(item2, i2) in item1.children"
                  :key="item2.id"
                >
                  <el-col :span="6">
                    <!-- closable 属性可以定义一个标签是否可移除 -->
                    <el-tag
                      type="success"
                      closable
                      @close="removeRightById(scope.row, item2.id)"
                      >{{ item2.name }}</el-tag
                    >
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <el-col :span="18">
                    <!-- closable 属性可以定义一个标签是否可移除 -->
                    <el-tag
                      type="warning"
                      v-for="item3 in item2.children"
                      :key="item3.id"
                      closable
                      @close="removeRightById(scope.row, item3.id)"
                    >
                      {{ item3.name }}
                    </el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <!-- 索引列 -->
        <el-table-column type="index"></el-table-column>
        <el-table-column label="角色名称" prop="roleName"  width="210"/>
        <el-table-column label="角色代码" prop="code"  width="210" />
        <el-table-column label="角色描述" prop="roleExplain" width="438"/>
        <el-table-column label="操作" width="300px">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 
          :disabled="scope.row.isSadmin" 如果未超级管理员则禁用所有操作-->
          <template slot-scope="scope">
            <el-button round size="mini" type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row.id)"
              :disabled="scope.row.isSadmin" >编辑</el-button
            >
             <!-- round 圆形按钮 -->
            <el-button round size="mini" type="danger" icon="el-icon-delete"
             :disabled="scope.row.isSadmin" 
              @click="removeRoleById(scope.row.id)">删除</el-button
            >
            <el-button
              round
              size="mini"
              type="warning"
              icon="el-icon-setting"
              @click="showSetRightDialog(scope.row)"
               :disabled="scope.row.isSadmin">分配权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>


       <!-- 分页区域 -->
      <!--  changeCurrentPage()不能加括号，应该下面函数有参数，这里不给值默认会传undefined -->
      <!-- 
         @size-change 监听 pagesize改变的事件 （选择显示多少条数据）
         @current-change  currentPage当前页数 改变时会触发 下一页
          current-page当前页数
          page-sizes 每页显示个数选择器的选项设置
          total总条目数 page-size每页显示条目个数默认10 prev上一页 pager页数 next下一页
          sizes选择器
         -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>



    
   

    <!-- 分配权限的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="分配权限"
      :visible.sync="setRightDialogVisible"
      width="50%"
      @close="setRightDialogClosed"
    >
     <span class="yinyong">选择应用:</span>
          <el-select
            v-model="appId"
            placeholder="请选择"
            @change="changeAppMenu"
          >
            <el-option
              v-for="item in appList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
      <!--树形控件  -->
      <!-- rightslist绑定数据  treeProps绑定数据中的相关字段 
      node-key="id"每个树节点用来作为唯一标识的属性,id为数据中的id字段  
      show-checkbox显示复选框
      default-expand-all 展开所有节点
      default-checked-keys 默认勾选的节点的 key 的数组
      ref="treeRef" 表示引用，指定哪一个组件下的函数this.$refs.treeRef.getCheckedKeys()-->
      <el-tree
        :data="rightslist"
        :props="treeProps"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="defkeys"
        ref="treeRef"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotRights">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加角色的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="新增角色"
      :visible.sync="addDialogVisbile"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 
      addFunForm添加表单里面的数据一定要写全，不然重置表单会有些无法重置-->
      <el-form
        ref="addRolesFormRef"
        :model="addForm"
        label-width="100px"
        :inline="false"
        :rules="addFormRules"
      >
      <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功 -->
        <el-form-item label="角色名" prop="roleName">
          <el-input v-model="addForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色说明" prop="roleExplain">
          <el-input v-model="addForm.roleExplain" :rows="5" type="textarea" />
        </el-form-item>
           <el-form-item label="角色排序" prop="orderId">
            <el-input-number v-model="addForm.orderId" :min="0" />
          </el-form-item>
          <el-form-item label="是否管理员" prop="isAdmin">
             <el-switch
              v-model="addForm.isAdmin"
            ></el-switch>
          </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisbile = false">取 消</el-button>
        <el-button type="primary" @click="addRole">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 编辑角色的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="编辑角色"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 -->
      <el-form
        ref="editFormRef"
        :model="editForm"
        label-width="100px"
        :inline="false"
        :rules="editFormRules"
      >
        <el-form-item label="角色名" prop="roleName">
          <el-input v-model="editForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色说明">
          <el-input v-model="editForm.roleExplain" :rows="5" type="textarea" />
        </el-form-item>
           <el-form-item label="角色排序">
            <el-input-number v-model="editForm.orderId" :min="0" />
          </el-form-item>
          <el-form-item label="是否管理员">
             <el-switch
              v-model="editForm.isAdmin"
            ></el-switch>
          </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoleInfo">确 定</el-button>
      </span>
    </el-dialog>







  </div>
</template>

<script>
export default {
  data() {
    return {
      //获取角色列表的参数对象
      queryInfo: {
        roleName: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 4,
      },
      //总记录数
      total: 0,
      //所有角色列表数据
      rolelist: [],
      //控制分配权限对话框的显示与隐藏
      setRightDialogVisible: false,
      //所有权限的数据
      rightslist: [],
      //树形控件的绑定对象   labael: 数据中显示文本内容的字段定义  children 数据中字节点的字段
      treeProps: {
        label: 'name',
        children: 'children',
      },
      //默认选中的节点Id值数组
      defkeys: [],
      //当前即将分配权限的角色id
      roleId: '',
      //控制添加角色对话框的显示与隐藏
      addDialogVisbile: false,
      //控制编辑角色对话框的显示与隐藏
      editDialogVisible: false,
      //添加角色的表单数据
      addForm: {
        roleName: '',
        roleExplain: '',
        isSadmin: '',
        isAdmin: false,
        orderId:0
      },

      //添加角色的表单数据
      editForm: {
      },
      //添加分类表单的验证规则对象
      addFormRules: {
        roleName: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入角色名', trigger: 'blur' },
        ],
      },
      editFormRules: {
        roleName: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入角色名', trigger: 'blur' },
        ],
      },
      //应用app列表
      appList: [],
      //下拉应用传递的id
      appId: '2',
      //存储当前角色信息
      roleInfo:{}
    }
  },
  created() {
    this.getRolesList()
     this.getAppList()
  },
  methods: {
    //获取所有角色的列表
    async getRolesList() {
      const { data: res } = await this.$http.get('admin/role/list',{
        params: this.queryInfo,
      })
      if (res.code !== 200) {
        return this.$message.error('获取角色列表失败！')
      }

      this.rolelist = res.data.row
       //总记录数
      this.total = res.data.total
      // console.log(this.rolelist)
    },

    // 监听 pagesize改变的事件（选择显示多少条数据）
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize
      this.getRolesList()
    },
    //监听 页码值下一页 改变的事件
    handleCurrentChange(newPage) {
      // console.log(newPage)
      this.queryInfo.page = newPage
      this.getRolesList()
    },


    //根据id删除对应的权限
    async removeRightById(role, rightId) {
      //弹框提示用户是否要删除
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该功能权限, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('取消了删除')
      }
      // console.log('确认了删除')
      const { data: res } = await this.$http.delete(
        `admin/permission/${role.id}/funcation/${rightId}`
      )

      if (res.code !== 200) {
        return this.$message.error('删除权限失败!')
      }
      //刷新权限列表(体验不太好，因为会刷新页面，下拉的权限会合上)
      // this.getRolesList()
      //直接将最新的数据赋值给传值进来的权限role里面，会自动的绑定要页面
      role.children = res.data.data
    },
    //展示分配权限的对话框
    async showSetRightDialog(role) {
      //当触发分配权限对话框中的应用选择时，要传一个role对象
      this.roleInfo = role
      // console.log(this.roleInfo)
      //将当前的角色id赋值，用于添加角色下的功能权限
      this.roleId = role.id
      //获取所有的权限的数据
      const { data: res } = await this.$http.get('/admin/menu/getMenu',{
        params:{'appId':this.appId}
      })
      // console.log(res)
      if (res.code !== 200) {
        return this.$message.error('获取权限数据失败！')
      }
      //把获取到的权限数据保存到data中
      this.rightslist = res.data.data

      // console.log(this.rightslist)
      //递归获取三级节点的Id
      this.getLeafkeys(role, this.defkeys)
      //打开弹框
      this.setRightDialogVisible = true
    },
    //通过递归的形式，获取角色下所有三级权限id，并保存到defkeyssu数组中去
    getLeafkeys(node, arr) {
      //如果当前node节点不包含children属性，则是三级节点
      // console.log(node.children)
      if (node.children == undefined || node.children.length <= 0) {
        return arr.push(node.id)
      }
      //遍历到最后一级目录
      node.children.forEach((item) => this.getLeafkeys(item, arr))
    },

    //监听分配权限对话框的关闭事件
    setRightDialogClosed() {
      this.defkeys = []
    },

    //点击为角色分配权限
    async allotRights() {
      const keys = [
        //...对数组和对象而言，就是将运算符后面的变量里东西每一项拆下来。
        //getCheckedKeys（）则返回目前被选中的节点的 key 所组成的数组
        ...this.$refs.treeRef.getCheckedKeys(),
        //getHalfCheckedKeys()则返回目前半选中的节点的 key 所组成的数组
        ...this.$refs.treeRef.getHalfCheckedKeys(),
      ]
      //根据,将数组转为字符串拼接
      const idStr = keys.join(',')
      const { data: res } = await this.$http.post(
        `/admin/permission/role/${this.roleId}`,
        { menuIds: idStr }
      )

      if (res.code !== 200) {
        return this.$message.error('分配权限失败')
      }
      this.$message.success('分配权限成功')
      this.getRolesList()

      this.setRightDialogVisible = false
    },

      //点击按钮，添加新角色
    addRole() {
      //validate()对整个表单进行校验的方法，参数为一个回调函数。该回调函数会在校验结束后被调用，
      //并传入两个参数：是否校验成功和未通过校验的字段。若不传入回调函数，则会返回一个 promise
      this.$refs.addRolesFormRef.validate(async (valid) => {
         if (!valid) return
        //可以发起添加角色的网络请求
        const {data:res} = await this.$http.post(
          '/admin/role/addRole',
          this.addForm
        )
        console.log(res)

        if (res.code != 200) {
          return this.$message.error(res.msg)
        } 

        this.$message.success('添加角色成功！')
        //隐藏添加角色的对话框
        this.addDialogVisbile = false
        //重新获取角色列表数据
        this.getRolesList()
      })
    },

     //监听添加角色对话框的关闭事件
    addDialogClosed() {
      // console.log(this.addForm)
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.addRolesFormRef.resetFields()
    },

      // 重置表单
    resetData() {
      this.queryInfo = {
        roleName: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 4,
      }
      this.getRolesList()
    },

    //展示编辑用户的对话框
    async showEditDialog(id) {
       const { data: res } = await this.$http.get('admin/role/'+id)
       if (res.code !== 200) {
        return this.$message.error('查询角色信息失败')
      }
      // console.log(id)t
      //赋值给编辑用户对象
      this.editForm = res.data.data
      //点击编辑按钮打开对话框
      this.editDialogVisible = true
    },

     //监听修改用户对话框的关闭事件
    editDialogClosed() {
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.editFormRef.resetFields()
    },

     //修改角色信息并提交
    editRoleInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        //发起修改角色信息的数据请求
        const { data: res } = await this.$http.put(
          '/admin/role/update',
          this.editForm
        )
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        //关闭对话框
        this.editDialogVisible = false
        //刷新数据列表
        this.getRolesList()
        //提示修改成功
        this.$message.success('更新用户信息成功！')
      })
    },

    //根据用户id删除用户
    async removeRoleById(id) {
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该角色, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      //如果用户确认删除，则返回值为字符串confirm
      //如果用户取消了删除，则返回值为字符串 cancel
      //  console.log(res)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/role/delete/' + id)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success('删除用户成功！')
      //刷新列表
      this.getRolesList()
    },

    //获取应用数据
    async getAppList() {
      const { data: res } = await this.$http.get('admin/app/getAll')

      if (res.code !== 200) {
        return this.$message.error('获取应用信息失败！')
      }
      // console.log(res.data)
      //把数据列表赋值给catelist
      this.appList = res.data.data
    },

     //当下拉菜单选中当前应用显示当前应用下的功能权限
    async changeAppMenu() {
      //this.roleInfo在打开分配权限对话框时赋值
      this.showSetRightDialog(this.roleInfo)
    }



  },
}
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}

.bdtop {
  border-top: 1px solid #eee;
}

.bdbottom {
  border-top: 1px solid #eee;
}

.vcenter {
  display: flex;
  align-items: center;
}
.el-icon-plus {
  margin-right: 5px;
}
.yinyong{
  margin-right: 10px;
  color: #1a1a1a;
  font-size: 15px;
  margin-bottom: 10px;
}
.el-select{
  margin-bottom: 10px;
}
</style>