<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col :span="2">
          <el-button type="primary" @click="showAddCateDialog"
            ><i class="el-icon-plus"></i>新增</el-button
          >
        </el-col>
        <el-col :span="6">
          <span class="yinyong">选择应用:</span>
          <el-select
            v-model="appId"
            placeholder="请选择"
            @change="changeAppMenu"
          >
            <el-option
              v-for="item in appList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- 表格 -->
      <!--
            :data绑定数据  
            :columns指定每行的标题和数据字段名
            :selection-type="false" 关闭复选框
            :expand-type 是否为展开行类型表格
            :show-index 是否展示数据索引 
            index-text 索引名称
             :is-fold="false" 展开所有数据
            border 是否显示边框
            show-row-hover 鼠标悬停时，是否高亮-->
      <tree-table
        class="treeTable"
        :data="menuList"
        :columns="columns"
        :selection-type="false"
        :expand-type="false"
        :show-index="false"
        index-text="#"
        :is-fold="false"
        border
        show-row-hover
      >
        <!-- 是否有效 
      slot="isok"对应指定列，下面要一一对应使用模板名称-->
        <template slot="isok" slot-scope="scope">
          <i
            class="el-icon-success"
            v-if="scope.row.isDisabled === false"
            style="color: lightgreen"
          ></i>
          <i class="el-icon-error" v-else style="color: red"></i>
        </template>
        <!-- 菜单代码 -->
        <template slot="code" slot-scope="scope">
          <span>{{ scope.row.code }}</span>
          <!-- <el-table-column width="210">{{scope.row.functionRsp.mark}}</el-table-column> -->
        </template>
        <!-- URL地址 -->
        <template slot="url" slot-scope="scope">
          <span>{{ scope.row.path }}</span>
          <!-- <el-table-column width="210">{{
            scope.row.functionRsp.url
          }}</el-table-column> -->
        </template>

        <!-- 排序 -->
        <template slot="order" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.level === 0">一级</el-tag>
          <el-tag type="success" size="mini" v-else-if="scope.row.level === 1"
            >二级</el-tag
          >
          <el-tag type="warning" size="mini" v-else>三级</el-tag>
        </template>

        <!-- 图标 -->
        <template slot="icon" slot-scope="scope">
          <!--  effect="dark" 改变主题，默认为 light -->
          <el-tag
            effect="dark"
            v-if="scope.row.icon !== null && scope.row.icon.length !==0 && scope.row.icon !== undefined"
            type="warning"
            size="mini"
            >{{ scope.row.icon }}</el-tag
          >
        </template>

        <!-- 操作 -->
        <template slot="opt" slot-scope="scope">
          <!-- round 圆形按钮 -->
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="showEditDialog(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            round
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="showDeleteDialog(scope.row.id)"
            >删除</el-button
          >
        </template>
      </tree-table>

      <!-- 分页区域 -->
      <!--  changeCurrentPage()不能加括号，应该下面函数有参数，这里不给值默认会传undefined -->
      <!-- 
         @size-change 监听 pagesize改变的事件 （选择显示多少条数据）
         @current-change  currentPage当前页数 改变时会触发 下一页
          current-page当前页数
          page-sizes 每页显示个数选择器的选项设置
          total总条目数 page-size每页显示条目个数默认10 prev上一页 pager页数 next下一页
          sizes选择器
         -->
      <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="querInfo.pagenum"
      :page-sizes="[3, 5, 10, 15]"
      :page-size="querInfo.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->
    </el-card>

    <!-- 添加菜单的对话框 -->
    <el-dialog
      title="添加菜单"
      :visible.sync="addMenuDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值
      addFunForm添加表单里面的数据一定要写全，不然重置表单会有些无法重置 -->
      <el-form
        :model="addMenuForm"
        :rules="addMenuFormRules"
        ref="addMenuFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- prop表单验证规则具体属性 disabled禁止修改-->
        <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功 -->
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="addMenuForm.name"></el-input>
        </el-form-item>
        <el-form-item label="URL地址：" prop="path">
          <el-input v-model="addMenuForm.path"></el-input>
        </el-form-item>

        <el-form-item label="绑定功能：" prop="funcId">
          <el-select v-model="addMenuForm.funcId" placeholder="请选择">
            <el-option
              v-for="item in functionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序：" prop="orderId">
          <el-input-number v-model="addMenuForm.orderId" :min="0" />
        </el-form-item>
        <el-form-item label="上级菜单：">
          <!-- 
        expand-trigger= "hover" 当鼠标放上去自动显示子分类
       options属性指定选项数组即可渲染出一个级联选择器
       通过props.expandTrigger可以定义展开子级菜单的触发方式 
       @change选择项发生变化触发这个函数
       通过 clearable 设置输入框可清空-->
          <el-cascader
            v-model="selectedKeys"
            :options="menuList"
            expand-trigger="hover"
            :props="cascaderProps"
            @change="parentMenuChanged"
            change-on-select
            clearable
          ></el-cascader>
        </el-form-item>
        <!-- 图标 -->
        <el-form-item label="图标："  prop="icon">
          <el-radio-group v-model="addMenuForm.icon" @change="handleChange">
            <el-tooltip content="iconHome" placement="top" effect="light">
              <el-radio label="iconHome"><i class="iconNav iconHome"></i></el-radio>
            </el-tooltip>
            <el-tooltip content="iconCommodity" placement="top" effect="light">
            <el-radio label="iconCommodity"><i class="iconNav iconCommodity"></i></el-radio>
            </el-tooltip>
             <el-tooltip content="iconOrder" placement="top" effect="light">
                <el-radio label="iconOrder"><i class="iconNav iconOrder"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconStock" placement="top" effect="light">
                 <el-radio label="iconStock"><i class="iconNav iconStock"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconUser" placement="top" effect="light">
               <el-radio label="iconUser"><i class="iconNav iconUser"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconPromotion" placement="top" effect="light">
                <el-radio label="iconPromotion"><i class="iconNav iconPromotion"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconOperate" placement="top" effect="light">
                 <el-radio label="iconOperate"><i class="iconNav iconOperate"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconContent" placement="top" effect="light">
                 <el-radio label="iconContent"><i class="iconNav iconContent"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconStatistics" placement="top" effect="light">
                  <el-radio label="iconStatistics"><i class="iconNav iconStatistics"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconFinance" placement="top" effect="light">
                 <el-radio label="iconFinance"><i class="iconNav iconFinance"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconSeting" placement="top" effect="light">
                <el-radio label="iconSeting"><i class="iconNav iconSeting"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconJurisdiction" placement="top" effect="light">
                 <el-radio label="iconJurisdiction"><i class="iconNav iconJurisdiction"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconSp" placement="top" effect="light">
                 <el-radio label="iconSp"><i class="icon iconSp"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconSet" placement="top" effect="light">
                 <el-radio label="iconSet"><i class="icon iconSet"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="null" placement="top" effect="light">
                 <el-radio label="">空</el-radio>
             </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否禁用：" prop="isDisabled">
          <el-switch v-model="addMenuForm.isDisabled"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMenuDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMenu">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑菜单的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 
    -->
    <el-dialog
      title="编辑菜单"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 -->
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="100px"
        :inline="false"
      >
        <!-- prop表单验证规则具体属性 disabled禁止修改-->
        <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功 -->
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="URL地址：">
          <el-input v-model="editForm.path"></el-input>
        </el-form-item>

        <el-form-item label="绑定功能：">
          <!-- clearable属性，则可将选择器清空 -->
          <el-select v-model="editForm.funcId" clearable placeholder="请选择">
            <el-option
              v-for="item in functionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序：">
          <el-input-number v-model="editForm.orderId" :min="0" />
        </el-form-item>
        <el-form-item label="上级菜单：">
          <!-- 
        expand-trigger= "hover" 当鼠标放上去自动显示子分类
       options属性指定选项数组即可渲染出一个级联选择器
       通过props.expandTrigger可以定义展开子级菜单的触发方式 
       @change选择项发生变化触发这个函数
       通过 clearable 设置输入框可清空-->
          <el-cascader
            v-model="selectedKeys"
            :options="menuList"
            expand-trigger="hover"
            :props="cascaderProps"
            @change="parentMenuChanged"
            change-on-select
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="图标：">
          <el-radio-group v-model="editForm.icon" @change="editHandleChange">
            <el-tooltip content="iconHome" placement="top" effect="light">
              <el-radio label="iconHome"><i class="iconNav iconHome"></i></el-radio>
            </el-tooltip>
            <el-tooltip content="iconCommodity" placement="top" effect="light">
            <el-radio label="iconCommodity"><i class="iconNav iconCommodity"></i></el-radio>
            </el-tooltip>
             <el-tooltip content="iconOrder" placement="top" effect="light">
                <el-radio label="iconOrder"><i class="iconNav iconOrder"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconStock" placement="top" effect="light">
                 <el-radio label="iconStock"><i class="iconNav iconStock"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconUser" placement="top" effect="light">
               <el-radio label="iconUser"><i class="iconNav iconUser"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconPromotion" placement="top" effect="light">
                <el-radio label="iconPromotion"><i class="iconNav iconPromotion"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconOperate" placement="top" effect="light">
                 <el-radio label="iconOperate"><i class="iconNav iconOperate"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconContent" placement="top" effect="light">
                 <el-radio label="iconContent"><i class="iconNav iconContent"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconStatistics" placement="top" effect="light">
                  <el-radio label="iconStatistics"><i class="iconNav iconStatistics"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="iconFinance" placement="top" effect="light">
                 <el-radio label="iconFinance"><i class="iconNav iconFinance"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconSeting" placement="top" effect="light">
                <el-radio label="iconSeting"><i class="iconNav iconSeting"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconJurisdiction" placement="top" effect="light">
                 <el-radio label="iconJurisdiction"><i class="iconNav iconJurisdiction"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconSp" placement="top" effect="light">
                 <el-radio label="iconSp"><i class="icon iconSp"></i></el-radio>
             </el-tooltip>
              <el-tooltip content="iconSet" placement="top" effect="light">
                 <el-radio label="iconSet"><i class="icon iconSet"></i></el-radio>
             </el-tooltip>
             <el-tooltip content="null" placement="top" effect="light">
                 <el-radio label="">空</el-radio>
             </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否禁用：">
          <el-switch v-model="editForm.isDisabled"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editMenuInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除功能 -->
    <el-dialog
      title="提示"
      type="warning"
      :visible.sync="deleteDialogVisible"
      width="30%"
    >
      <!-- closable属性决定是否可关闭 -->
      <el-alert
        title="此操作将永久删除该菜单, 是否继续?"
        type="warning"
        show-icon
        :closable="false"
      >
      </el-alert>
      <!-- `checked` 为 true 或 false -->
      <el-checkbox v-model="checked">同时删除子菜单？</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="removeMenuById">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //菜单的数据列表
      menuList: [],
      //为table指定列的定义 label标题 prop显示数据的字段名
      columns: [
        {
          label: '分类名称',
          prop: 'name',
        },
        {
          label: '菜单代码',
          //表示，将当前列定义为模板列
          type: 'template',
          //表示当前这一列使用模板名称
          template: 'code',
        },
        {
          label: 'URL地址',
          //表示，将当前列定义为模板列
          type: 'template',
          //表示当前这一列使用模板名称
          template: 'url',
        },
        {
          label: '是否有效',
          //表示，将当前列定义为模板列
          type: 'template',
          //表示当前这一列使用模板名称
          template: 'isok',
        },
        {
          label: '排序',
          //表示，将当前列定义为模板列
          type: 'template',
          //表示当前这一列使用模板名称
          template: 'order',
        },
        {
          label: '图标',
          //表示，将当前列定义为模板列
          type: 'template',
          //表示当前这一列使用模板名称
          template: 'icon',
          width: 130,
        },
        {
          label: '操作',
          //表示，将当前列定义为模板列
          type: 'template',
          //表示当前这一列使用模板名称
          template: 'opt',
        },
      ],
      //控制添加分类对话框的显示与隐藏
      addMenuDialogVisible: false,
      //添加菜单的表单数据对象
      addMenuForm: {
        //将要添加的分类的名称
        name: '',
        //父级分类的id
        parentId: 0,
        //分类的等级，默认要添加的是一级分类
        level: 0,
        //绑定的功能id
        funcId: '',
        //排序id
        orderId: 0,
        //图标默认选中
        icon: '',
      },

      //添加分类表单的验证规则对象
      addMenuFormRules: {
        name: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
      },

      //父级分类的列表
      parentMenuList: [],
      //指定级联选择器的配置对象
      //value 选中传递的值  label显示的值 children嵌套的字段
      cascaderProps: {
        value: 'id',
        label: 'name',
        children: 'children',
      },
      //选中的父级分类的Id数组
      selectedKeys: [],

      //应用app列表
      appList: [],
      //下拉应用传递的id
      appId: '2',
      //下拉功能列表
      functionList: [],

      //控制编辑功能对话框的显示与隐藏
      editDialogVisible: false,
      //编辑菜单的表单数据
      editForm: {},
      //编辑菜单数据校验
      editFormRules: {
        name: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入菜单名', trigger: 'blur' },
        ],
      },

      //删除对话框显示
      deleteDialogVisible: false,
      //是否解除菜单关联
      checked: false,
      //菜单删除的id
      menuId: '',
    }
  },
  created() {
    this.getMenuList()
    this.getAppList()
    this.getFunction()
  },
  methods: {
    //获取菜单数据
    async getMenuList() {
      const { data: res } = await this.$http.get('admin/menu/getMenu', {
        params: { appId: this.appId },
      })

      if (res.code !== 200) {
        return this.$message.error('获取菜单数据失败！')
      }
      // console.log(res.data)
      //把数据列表赋值给catelist
      this.menuList = res.data.data
    },

    //获取应用数据
    async getAppList() {
      const { data: res } = await this.$http.get('admin/app/getAll')

      if (res.code !== 200) {
        return this.$message.error('获取应用信息失败！')
      }
      // console.log(res.data)
      //把数据列表赋值给catelist
      this.appList = res.data.data
    },
    //获取功能列表
    async getFunction() {
      const { data: res } = await this.$http.get(
        'admin/function/getFunByAppId',
        { params: { appId: this.appId } }
      )
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.functionList = res.data.data

      // console.log(this.functionList)
    },

    //添加按钮，展示添加分类的对话框
    showAddCateDialog() {
      //展示出对话框
      this.addMenuDialogVisible = true
    },

    //选择项发生变化触发这个函数
    parentMenuChanged() {
      // console.log(this.selectedKeys)
      //如果selectedKeys数组中的length 大于0，证明选中的父级分类
      //反之，就说明没有选中任何父级分类
      if (this.selectedKeys.length > 0) {
        //父级分类的id
        this.addMenuForm.parentId =
          this.selectedKeys[this.selectedKeys.length - 1]
        //为当前分类的等级赋值
        this.addMenuForm.level = this.selectedKeys.length
        //编辑菜单设置
        this.editForm.parentId = this.selectedKeys[this.selectedKeys.length - 1]
        //为当前分类的等级赋值
        this.editForm.level = this.selectedKeys.length
        return
      } else {
        //父级分类的id
        this.addMenuForm.parentId = 0
        //为当前分类的等级赋值
        this.addMenuForm.level = 0
        //编辑菜单设置
        //父级分类的id
        this.editForm.parentId = 0
        //为当前分类的等级赋值
        this.editForm.level = 0
      }
    },
    //当下拉菜单选中当前应用显示当前应用下的菜单权限
    async changeAppMenu() {
      // console.log(this.appId)
      //刷新菜单列表
      this.getMenuList()
      //刷新功能列表
      this.getFunction()
    },

    //添加菜单
    addMenu() {
      this.$refs.addMenuFormRef.validate(async (valid) => {
        if (!valid) return
        //应用id
        this.addMenuForm.appId = this.appId
        const { data: res } = await this.$http.post(
          '/admin/menu/add',
          this.addMenuForm
        )
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success('添加菜单成功！')
        //隐藏添加菜单的对话框
        this.addMenuDialogVisible = false
        //重新获取菜单列表数据
        this.getMenuList()
        // console.log(this.addFunForm)
      })
    },

    //监听修改菜单对话框的关闭事件
    editDialogClosed() {
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.editFormRef.resetFields()
    },

    //展示编辑菜单的对话框
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('admin/menu/' + id)
      if (res.code !== 200) {
        return this.$message.error('查询菜单信息失败')
      }
      // console.log(queryInfo)
      //赋值给编辑菜单对象
      this.editForm = res.data.data
      //给级联选择器赋值，展示上级菜单
      this.selectedKeys = this.editForm.parentId
      //点击编辑按钮打开对话框
      this.editDialogVisible = true
    },

    //修改菜单信息并提交
    editMenuInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        //设置当前的应用id
        this.editForm.appId = this.appId
        //发起修改菜单信息的数据请求
        const { data: res } = await this.$http.put(
          '/admin/menu/update',
          this.editForm
        )
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        //关闭对话框
        this.editDialogVisible = false
        //刷新数据列表
        this.getMenuList()
        //提示修改成功
        this.$message.success('更新菜单信息成功！')
      })
    },

    //展示删除菜单的对话框
    showDeleteDialog(id) {
      this.menuId = id
      this.deleteDialogVisible = true
    },

    //根据id删除对应的菜单
    async removeMenuById() {
      // console.log('确认了删除')
      const { data: res } = await this.$http.delete(
        `admin/menu/delete/${this.menuId}`,
        {
          params: { isConform: this.checked },
        }
      )

      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success('删除菜单成功！')
      //关闭对话框
      this.deleteDialogVisible = false
      //刷新菜单列表
      this.getMenuList()
    },

    //监听添加功能对话框的关闭事件
    addDialogClosed() {
      // console.log(this.addForm)
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.addMenuFormRef.resetFields()
    },

    //添加图标单选框触发
    handleChange(val) {
      this.addMenuForm.icon = val
      // console.log(val)
    },

    //编辑图标单选框触发
    editHandleChange(val) {
      this.editForm.icon = val
    },
  },
}
</script>

<style lang="less" scoped>
.treeTable {
  margin-top: 15px;
}
.el-cascader {
  width: 100%;
}
.el-icon-plus {
  margin-right: 5px;
}
.el-checkbox {
  margin: 15px 15px;
}
.el-radio {
  margin-right: 70px;
  margin-top: 20px;
}
.yinyong{
  margin-right: 10px;
  color: #1a1a1a;
  font-size: 15px;
}
</style>