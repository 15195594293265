<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>功能管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col :span="2">
          <el-button type="primary" @click="showAddFunDialog"
            ><i class="el-icon-plus"></i>新增</el-button
          >
        </el-col>
        <el-col :span="7">
          <span class="yinyong">选择应用:</span>
          <el-select
            v-model="queryInfo.appId"
            placeholder="请选择"
            @change="changeAppMenu"
          >
            <el-option
              v-for="item in appList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>

        <!-- clearable 得到一个可清空的输入框 -->
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.name"
            clearable
            @clear="getFunList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getFunList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="default" @click="resetData()">清空</el-button>
        </el-col>
      </el-row>

      <!-- 表格 -->
      <!-- 卡片视图 -->
      <!-- border边框线  stripe创建带斑马纹的表格  type="index"索引列-->
      <el-table :data="functionList" border stripe>
        <el-table-column type="index">
          <template slot-scope="scope">
            <!-- scope.$index当前这一行的索引0，1，2，3，4，5 -->
            {{ (queryInfo.page - 1) * queryInfo.limit + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="功能名称" prop="name" />
        <el-table-column label="标识" prop="mark" />
        <el-table-column label="URL地址" prop="url" />
        <el-table-column label="是否禁用" prop="isDisabled">
          <template slot-scope="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.isDisabled === false"
              style="color: lightgreen"
            ></i>
            <i class="el-icon-error" v-else style="color: red"></i>
          </template>
        </el-table-column>
        <el-table-column label="权限等级" prop="level">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 -->
          <template slot-scope="scope">
            <el-tag v-if="scope.row.level === 0">一级权限</el-tag>
            <el-tag type="success" v-else-if="scope.row.level === 1"
              >二级权限</el-tag
            >
            <el-tag type="warning" v-else-if="scope.row.level === 2"
              >三级权限</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300px">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 
          :disabled="scope.row.isSadmin" 如果未超级管理员则禁用所有操作-->
          <template slot-scope="scope">
            <el-button
              round
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="showEditDialog(scope.row.id)"
              :disabled="scope.row.isSadmin"
              >编辑</el-button
            >
             <!-- round 圆形按钮 -->
            <el-button
              round
              size="mini"
              type="danger"
              icon="el-icon-delete"
              :disabled="scope.row.isSadmin"
              @click="showDeleteDialog(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <!--  changeCurrentPage()不能加括号，应该下面函数有参数，这里不给值默认会传undefined -->
      <!-- 
         @size-change 监听 pagesize改变的事件 （选择显示多少条数据）
         @current-change  currentPage当前页数 改变时会触发 下一页
          current-page当前页数
          page-sizes 每页显示个数选择器的选项设置
          total总条目数 page-size每页显示条目个数默认10 prev上一页 pager页数 next下一页
          sizes选择器
         -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加功能的对话框 -->
    <el-dialog title="添加功能" :visible.sync="addFunDialogVisible" width="50%"  @close="addDialogClosed">
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 
      addFunForm添加表单里面的数据一定要写全，不然重置表单会有些无法重置 -->
      <el-form
        :model="addFunForm"
        :rules="addFunFormRules"
        ref="addFunFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功, disabled禁止修改-->
        <el-form-item label="功能名称:" prop="name">
          <el-input v-model="addFunForm.name"></el-input>
        </el-form-item>
        <el-form-item label="URL地址:" prop="url">
          <el-input v-model="addFunForm.url"></el-input>
        </el-form-item>
        <el-form-item label="权限等级:" prop="level">
          <el-select v-model="addFunForm.level" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="orderId">
          <el-input-number v-model="addFunForm.orderId" :min="0" />
        </el-form-item>
        <el-form-item label="是否禁用" prop="isDisabled">
          <el-switch v-model="addFunForm.isDisabled"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFunDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
    </el-dialog>


     <!-- 编辑功能的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 
    -->
    <el-dialog
      title="编辑功能"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 -->
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="100px"
        :inline="false"
      >
      <!-- prop不需要写对应的名字，不然会重置为上一次的值, disabled禁止修改-->
        <el-form-item label="功能名称:" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
       <el-form-item label="URL地址:" >
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="权限等级:" >
          <el-select v-model="editForm.level" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input-number v-model="editForm.orderId" :min="0" />
        </el-form-item>
        <el-form-item label="是否禁用" >
          <el-switch v-model="editForm.isDisabled"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoleInfo">确 定</el-button>
      </span>
    </el-dialog>

     <!-- 删除功能 -->
    <el-dialog
  title="提示"
  type="warning"
  :visible.sync="deleteDialogVisible"
  width="30%"
  >
  <!-- closable属性决定是否可关闭 -->
  <el-alert
    title="此操作将永久删除该角色, 是否继续?"
    type="warning"
    show-icon
    :closable="false">
  </el-alert>
  <!-- `checked` 为 true 或 false -->
  <el-checkbox v-model="checked">是否解除菜单关联？</el-checkbox>
  <span slot="footer" class="dialog-footer">
    <el-button @click="deleteDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="removeRightById">确 定</el-button>
  </span>
</el-dialog>

  </div>
</template>


<script>
export default {
  data() {
    return {
      //获取功能列表的参数对象
      queryInfo: {
        name: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 5,
        //下拉应用传递的id
        appId: '2',
      },
      //功能权限列表
      functionList: [],
      //对话框显示
      addFunDialogVisible: false,
      //应用app列表
      appList: [],

      //添加功能的表单数据对象
      addFunForm: {
        //将要添加的分类的名称
        name: '',
        //功能的等级，默认要添加的是一级分类
        level: 0,
        url:'',
        orderId:'',
        isDisabled:false
      },

      //添加功能表单的验证规则对象
      addFunFormRules: {
        name: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入功能名称', trigger: 'blur' },
        ],
      },

       editFormRules: {
        name: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入功能名', trigger: 'blur' },
        ],
      },
      //总记录数
      total: 0,
      //权限等级
      options: [{
          value: 0,
          label: '一级权限'
        }, {
          value: 1,
          label: '二级权限'
        }, {
          value: 2,
          label: '三级权限'
        }],
       //控制编辑功能对话框的显示与隐藏
      editDialogVisible: false,
        //添加功能的表单数据
      editForm: {},
      //删除对话框显示
      deleteDialogVisible: false,
      //是否解除菜单关联
      checked:false,
      //功能删除的id
      functionId:''
      

    }
  },
  created() {
    this.getFunList()
    this.getAppList()
  },
  methods: {
    //添加按钮，展示添加分类的对话框
    showAddFunDialog() {
      //展示出对话框
      this.addFunDialogVisible = true
    },
    //展示删除功能的对话框
    showDeleteDialog(id){
      this.functionId = id
      this.deleteDialogVisible =true
    },

    //获取功能数据
    async getFunList() {
      const { data: res } = await this.$http.get('admin/function/list', {
        params: this.queryInfo,
      })

      if (res.code !== 200) {
        return this.$message.error('获取功能列表失败！')
      }
      // console.log(res.data)
      //把数据列表赋值给catelist
      this.functionList = res.data.data
      //总记录数
      this.total = res.data.total
    },

    // 监听 pagesize改变的事件（选择显示多少条数据）
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize
      this.getFunList()
    },
    //监听 页码值下一页 改变的事件
    handleCurrentChange(newPage) {
      // console.log(newPage)
      this.queryInfo.page = newPage
      this.getFunList()
    },

    //当下拉菜单选中当前应用显示当前应用下的功能权限
    async changeAppMenu() {
      this.getFunList()
    },

    //获取应用数据
    async getAppList() {
      const { data: res } = await this.$http.get('admin/app/getAll')

      if (res.code !== 200) {
        return this.$message.error('获取应用信息失败！')
      }
      // console.log(res.data)
      //把数据列表赋值给catelist
      this.appList = res.data.data
    },

    // 重置表单
    resetData() {
      this.queryInfo = {
        name: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 5,
      }
      this.getFunList()
    },
    //添加功能
    addFunction(){
      this.$refs.addFunFormRef.validate(async (valid) =>{
        if (!valid) return
        this.addFunForm.appId = this.queryInfo.appId
        const {data:res} = await this.$http.post(
          '/admin/function/add',
          this.addFunForm
        )
        if(res.code !== 200){
          return this.$message.error(res.msg)
        }
           this.$message.success('添加功能成功！')
        //隐藏添加功能的对话框
        this.addFunDialogVisible = false
        //重新获取功能列表数据
        this.getFunList()
      // console.log(this.addFunForm)
      })
    },

     //监听添加功能对话框的关闭事件
    addDialogClosed() {
      // console.log(this.addForm)
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.addFunFormRef.resetFields()
    },

    //展示编辑功能的对话框
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('admin/function/'+id)
       if (res.code !== 200) {
        return this.$message.error('查询功能信息失败')
      }
      // console.log(queryInfo)
      //赋值给编辑功能对象
      this.editForm = res.data.data
      //点击编辑按钮打开对话框
      this.editDialogVisible = true
    },

    //监听修改功能对话框的关闭事件
    editDialogClosed() {
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.editFormRef.resetFields()
    },

      //修改功能信息并提交
    editRoleInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        //设置当前的应用id
        this.editForm.appId = this.queryInfo.appId
        //发起修改功能信息的数据请求
        const { data: res } = await this.$http.put(
          '/admin/function/update',
          this.editForm
        )
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        //关闭对话框
        this.editDialogVisible = false
        //刷新数据列表
        this.getFunList()
        //提示修改成功
        this.$message.success('更新功能信息成功！')
      })
    },


     //根据id删除对应的权限
    async removeRightById() {
      // console.log('确认了删除')
      const { data: res } = await this.$http.delete(
        `admin/function/delete/${this.functionId}`,
        {
          params:{'isConform':this.checked}
        }
      )

      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success('删除功能成功！')
      //关闭对话框
      this.deleteDialogVisible = false
      //刷新功能列表
      this.getFunList()
    },

  },
}
</script>

<style lang="less" scoped>
.el-select {
  margin-right: 18px;
}
.el-checkbox{
  margin: 15px 15px;
}
.yinyong{
  margin-right: 10px;
  color: #1a1a1a;
  font-size: 15px;
}

</style>

