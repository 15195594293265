import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import Users from '../components/user/Users.vue'
import Rights from '../components/power/Rights.vue'
import Roles from '../components/power/Roles.vue'
import addUser from '../components/user/form.vue'
import Menu from '../components/system/menu.vue'
import Function from '../components/system/function.vue'
import App from '../components/system/app.vue'
import userCenter from '../components/user/userCenter.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
      path: '/home',
      component: Home,
      redirect: '/Welcome',
      children: [
        { path: '/Welcome', component: Welcome },
        { path: '/users', component: Users },
        {path:'/rights',component:Rights},
        {path:'/roles',component:Roles},
        {path:'/addUser',component:addUser},
        {path:'/menu',component:Menu},
        {path:'/function',component:Function}, 
        {path:'/app',component:App},  
        {path:'/userCenter',component:userCenter}  
    ]
    }
  ]
})


//挂载路由导航守卫
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数，表示放行
  //next（） 放行 next（'/login'） 强制跳转
  if (to.path === '/login') return next();
  //获取token
  const tokenStr = window.sessionStorage.getItem('token')
  //如果没有token跳转到登录页面
  if (!tokenStr) return next('/login')
  next()
})


export default router
