<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="22">
          <!-- clearable 得到一个可清空的输入框 -->
          <!-- <el-input
            placeholder="请输入内容"
            v-model="queryInfo.userName"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button>
          </el-input>-->

          <!--查询表单  :inline横向表单-->
          <el-form :inline="true">
            <!-- label组件前文本 -->
            <el-form-item label="用户姓名">
              <!-- <el-input v-model="searchObj.name" placeholder="讲师"/> -->
              <!-- trigger-on-focus点击不触发
        fetch-suggestions 是一个返回输入建议的方法属性
        value-key输入建议对象中用于显示的键名默认是value，要改为name，不然显示不出来 -->
              <el-autocomplete
                v-model="queryInfo.realName"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                class="inline-input"
                placeholder="请输入内容"
                value-key="real_name"
              />
            </el-form-item>

            <el-form-item>
              <!-- clearable删除已选的值 -->
              <!-- <el-select v-model="queryInfo.level" clearable placeholder="头衔">
          <el-option value="1" label="高级讲师"/>
          <el-option value="2" label="首席讲师"/>
        </el-select> -->
            </el-form-item>

            <el-form-item label="创建时间">
              <el-date-picker
                v-model="queryInfo.joinDateBegin"
                placeholder="开始时间"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item label="-">
              <el-date-picker
                v-model="queryInfo.joinDateEnd"
                placeholder="结束时间"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="getUserList()"
                >查询</el-button
              >
              <el-button type="default" @click="resetData()">清空</el-button>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisbile = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>

       <!-- 工具条 -->
    <div style="margin-bottom:10px">
      <el-button type="danger" size="mini" @click="batchRemove()">批量删除</el-button>
    </div>

      <!-- 用户列表区域 -->
      <!-- border边框线  stripe创建带斑马纹的表格  type="index"索引列
      @selection-change 当选择项发生变化时会触发该事件 -->
      <el-table :data="userlist" border stripe  @selection-change="handleSelectionChange">
        <el-table-column type="selection" />
        <el-table-column label="#" type="index">
          <template slot-scope="scope">
            <!-- scope.$index当前这一行的索引0，1，2，3，4，5 -->
            {{ (queryInfo.page - 1) * queryInfo.limit + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="用户名" prop="userName" width="100" />
        <el-table-column label="姓名" prop="realName" width="100" />
        <el-table-column label="电话" prop="phone" width="120"/>
        <el-table-column label="邮箱" prop="email" />

        <el-table-column label="角色" prop="" width="280">
          <template slot-scope="scope">
            <el-tag
              v-for="role in scope.row.roleList"
              :key="role.id"
              :type="role.isSadmin === true ? 'danger' : 'success'"
              disable-transitions
              >{{ role.roleName }}</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column label="状态">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 -->
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.freeze"
              @change="userStateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <!-- 作用域插槽 slot-scope="scope" {{scope.row}}获取这一行的数据 -->
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button
              round
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <!-- 删除按钮 -->
            <el-button
              round
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserById(scope.row.id)"
            ></el-button>
            <!-- 分配角色按钮 -->
            <!-- enterable 鼠标是否可进入到 tooltip 中 -->
            <el-tooltip
              effect="dark"
              content="分配角色"
              placement="top"
              :enterable="false"
            >
             <!-- round 圆形按钮 -->
              <el-button
                round
                type="warning"
                icon="el-icon-setting"
                size="mini"
                @click="setRole(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <!--  changeCurrentPage()不能加括号，应该下面函数有参数，这里不给值默认会传undefined -->
      <!-- 
         @size-change 监听 pagesize改变的事件 （选择显示多少条数据）
         @current-change  currentPage当前页数 改变时会触发 下一页
          current-page当前页数
          page-sizes 每页显示个数选择器的选项设置
          total总条目数 page-size每页显示条目个数默认10 prev上一页 pager页数 next下一页
          sizes选择器
         -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="新增用户"
      :visible.sync="addDialogVisbile"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值
      addFunForm添加表单里面的数据一定要写全，不然重置表单会有些无法重置 -->
      <el-form
        ref="addFormRef"
        :model="addForm"
        label-width="120px"
        :rules="addFormRules"
        :inline="true"
      >
      <!-- prop表单验证规则具体属性和重置表单时必须设置值才能重置成功 -->
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="addForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="addForm.realName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCard">
          <el-input v-model="addForm.idCard"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
         <el-form-item label="学校" prop="school">
          <el-input v-model="addForm.school"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用" prop="freeze">
          <el-switch v-model="addForm.freeze"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisbile = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="修改用户"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <!-- ref重置表单时需要获取全局对象；  rules 表单验证;  model 绑定input属性值 -->
      <el-form
        ref="editFormRef"
        :model="editForm"
        label-width="120px"
        :rules="editFormRules"
        :inline="true"
      >
        <!-- prop表单验证规则具体属性 disabled禁止修改-->
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="editForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="editForm.realName"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
           <el-form-item label="学校" prop="school">
          <el-input v-model="editForm.school"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCard">
          <el-input v-model="editForm.idCard"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用" prop="freeze">
          <el-switch v-model="editForm.freeze"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分配角色的对话框 -->
    <!-- :visible.sync= addDialogVisbile 为false 隐藏对话框 
    @close事件 当关闭表单重置表单数据 -->
    <el-dialog
      title="分配角色"
      :visible.sync="setRoleDialogVisible"
      width="30%"
      @close="setRoleDialogClosed"
    >
      <div>
        <p>当前的用户：{{ userInfo.userName }}</p>
        <p class="rolestly">
          当前的角色：
          <!-- 多选框组-->
          <!-- 
      v-model 绑定已选中的角色Id  来自:label的id值
      :label 显示角色id
     -->
          <el-checkbox-group v-model="roleIdList">
            <el-checkbox
              v-for="role in rolesList"
              :key="role.id"
              :label="role.id"
              >{{ role.roleName }}</el-checkbox
            >
          </el-checkbox-group>
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    //自定义验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      //验证通过
      if (regEmail.test(value)) {
        //合法的邮箱
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    //自定义验证手机号的规则
    var checkMoblie = (rule, value, cb) => {
      //验证手机号的正则表达式
      // const regMoblie = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      const regMoblie = /^1[1356789]\d{9}$/
      //验证通过
      if (regMoblie.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }

    return {
      //获取用户列表的参数对象
      queryInfo: {
        userName: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 4,
      },
      //用户列表
      userlist: [],
      total: 0,
      //控制添加用户对话框的显示与隐藏
      addDialogVisbile: false,
      //控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      //查询到的用户对象
      editForm: {},
      editFormRules: {
        email: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        phone: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入手机', trigger: 'blur' },
          { validator: checkMoblie, trigger: 'blur' },
        ],
        idCard: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern: /(^\d{15}$)|(^\d{17}(\d|X|x)$)/, //长度或格式校验
            message: '请输入正确的身份证',
            trigger: 'blur',
          },
        ],
      },

      //添加用户的表单数据
      addForm: {
        userName: '',
        password: '',
        email: '',
        phone: '',
      },

      //添加表单的验证规则对象
      addFormRules: {
        userName: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        email: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        phone: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入手机', trigger: 'blur' },
          { validator: checkMoblie, trigger: 'blur' },
        ],
        idCard: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern: /(^\d{15}$)|(^\d{17}(\d|X|x)$)/, //长度或格式校验
            message: '请输入正确的身份证',
            trigger: 'blur',
          },
        ],
      },

      //控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      //需要被分配角色用户信息
      userInfo: {},
      //所有的角色的数据列表
      rolesList: [],
      //已选中角色的ID值
      roleIdList: [],
      // 批量选中的记录列表
      multipleSelection: [] 
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    async getUserList() {
      const { data: res } = await this.$http.get('/admin/user/list', {
        params: this.queryInfo,
      })
      // console.log(res)
      if (res.code != 200) {
        return this.$message.error('获取用户列表失败!')
      }
      this.userlist = res.data.row
      //总记录数
      this.total = res.data.total
    },

    // 监听 pagesize改变的事件（选择显示多少条数据）
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize
      this.getUserList()
    },
    //监听 页码值下一页 改变的事件
    handleCurrentChange(newPage) {
      // console.log(newPage)
      this.queryInfo.page = newPage
      this.getUserList()
    },
    //监听swatch开关状态的改变
    async userStateChanged(userinfo) {
      // console.log(userinfo)
      const { data: res } = await this.$http.put(
        `admin/user/users/${userinfo.id}/state/${userinfo.freeze}`
      )
      // console.log(res.code)
      if (res.code != 200) {
        userinfo.mg_state = !userinfo.mg_state
        return this.$message.error('更新用户状态失败！')
      }
      this.$message.success('更新用户状态成功！')
    },

    //监听添加用户对话框的关闭事件
    addDialogClosed() {
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.addFormRef.resetFields()
    },
    //点击按钮，添加新用户
    addUser() {
      //validate()对整个表单进行校验的方法，参数为一个回调函数。该回调函数会在校验结束后被调用，
      //并传入两个参数：是否校验成功和未通过校验的字段。若不传入回调函数，则会返回一个 promise
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        //可以发起添加用户的网络请求
        const { data: res } = await this.$http.post(
          '/admin/user/addUser',
          this.addForm
        )

        if (res.code != 200) {
          this.$message.error(res.msg)
        }
        this.$message.success('添加用户成功！')
        //隐藏添加用户的对话框
        this.addDialogVisbile = false
        //重新获取用户列表数据
        this.getUserList()
      })
    },

    //展示编辑用户的对话框
    async showEditDialog(id) {
      // console.log(id)t
      const { data: res } = await this.$http.get('admin/user/getUserById/' + id)
      if (res.code !== 200) {
        return this.$message.error('查询用户信息失败')
      }
      //赋值给编辑用户对象
      this.editForm = res.data.user
      //点击编辑按钮打开对话框
      this.editDialogVisible = true
    },

    //监听修改用户对话框的关闭事件
    editDialogClosed() {
      // loginFormRef是el-form ref属性值   resetFields()对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.editFormRef.resetFields()
    },

    //修改用户信息并提交
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        //发起修改用户信息的数据请求
        const { data: res } = await this.$http.put(
          '/admin/user/update',
          this.editForm
        )
        if (res.code !== 200) {
          return this.$message.error('更新用户信息失败')
        }
        //关闭对话框
        this.editDialogVisible = false
        //刷新数据列表
        this.getUserList()
        //提示修改成功
        this.$message.success('更新用户信息成功！')
      })
    },

    //根据用户id删除用户
    async removeUserById(id) {
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      //如果用户确认删除，则返回值为字符串confirm
      //如果用户取消了删除，则返回值为字符串 cancel
      //  console.log(res)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/user/delete/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除用户失败')
      }
      this.$message.success('删除用户成功！')
      //刷新列表
      this.getUserList()
    },

    //展示分配角色的对话框
    async setRole(userinfo) {
      //拿到当前的用户信息
      this.userInfo = userinfo
      //获取当前用户的角色列表信息，加载到对话框
      for (var i = 0; i < userinfo.roleList.length; i++) {
        this.roleIdList.push(userinfo.roleList[i].id)
      }
      // console.log(userinfo)

      //在展示对话框之前,获取所有的角色列表
      const { data: res } = await this.$http.get('admin/role/getAllRole')

      // console.log(res)
      if (res.code !== 200) {
        return this.$message.error('获取角色列表失败！')
      }
      //获取角色列表
      this.rolesList = res.data.data
      //打开对话框
      this.setRoleDialogVisible = true
    },

    //点击按钮，分配角色
    async saveRoleInfo() {
      //选中角色id为空
      if (!this.roleIdList) {
        return this.$message.error('请选择要分配的角色！')
      }
      //根据,将数组转为字符串拼接
      const idStr = this.roleIdList.join(',')
      // console.log(idStr)
      const { data: res } = await this.$http.put(
        `admin/userRole/users/${this.userInfo.id}/role`,
        {
          roleIds: idStr,
        }
      )
      if (res.code !== 200) {
        return this.$message.error('更新角色失败')
      }

      this.$message.success('更新角色成功！')
      //刷新用户列表
      this.getUserList()
      //关闭对话框
      this.setRoleDialogVisible = false
    },

    //监听分配角色对话框的关闭事件
    setRoleDialogClosed() {
      this.roleIdList = []
      this.userInfo = {}
    },

    // 当表格多选项发送变化的时候触发
    handleSelectionChange(selection) {
      //selection选中的当前记录数据
      this.multipleSelection = selection
      // console.log(this.multipleSelection)
    },

    // 输入建议 queryString输入值
    async querySearch(queryString, callback) {
      // console.log('queryString', queryString)
      const { data: res } = await this.$http.get(
        `admin/user/list/name/${queryString}`
      )
      // 通过调用 callback(data:[]) 来返回它;参考文档内部定义了
      callback(res.data.nameList)
    },

    // 重置表单
    resetData() {
      this.queryInfo = {
        userName: '',
        //当前的页数
        page: 1,
        //当前每页显示多少条数据
        limit: 4,
      }
      this.getUserList()
    },

    // 批量删除
    batchRemove() {
      // 判断数据是否选中
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择要删除的记录'
        })
        return
      }

      // 询问是否删除
      this.$confirm('此操作将永久删除这些数据, 是否继续?', '提示', {

        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning' // 显示感叹号
      }).then(() => {
        // 拿到选中数据
        const idList = []
        this.multipleSelection.forEach(item => {
          idList.push(item.id) // 循环遍历出id赋值给idList
        })
        return  this.$http.delete('admin/user/batch-remove',{
          data:idList
        })
      }).then(response => {
        console.log(response)
        // 刷新页面
        this.getUserList()
        // 弹出成功提示
        this.$message({
          message: response.data.msg,
          type: 'success'
        })
      }).catch((err) => { // 点击取消操作 默认会传一个cancel值
        // console.log(err)
        if (err === 'cancel') {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }
      })
    },



  },
}
</script>

<style lang="less" scoped>
.rolestly {
  display: flex;
}
.el-checkbox-group {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
</style>