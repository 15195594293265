<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header style="height: 80px">
      <div>
        <i>
          <img src="../assets/img/title-left.png" />
        </i>
        <h3>权限管理平台</h3>
        <i>
          <img src="../assets/img/title-right.png" />
        </i>
      </div>
      <div style="display: contents">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="iconfont icon-morentouxiang"></i>
            {{ this.userInfo.realName
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-s-custom" command="userCenter"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-tickets" command="copyrightInfo">版本信息</el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-check" command="logout"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <el-button type="info" @click="logout">退出</el-button> -->
    </el-header>

    <!-- 页面主体区域 -->
    <el-container>
      <div :class="[isCollapse ? '' : 'aside']">
        <!-- 侧边栏 -->
        <el-aside :width="isCollapse ? '64px' : '200px'">
          <!-- 折叠按钮 -->
          <div class="toggle-button" @click="toggleCollapse()">
            <i class="el-icon-s-fold"></i>
          </div>
          <!-- 侧边栏菜单区域 -->
          <!--unique-opened 是否只保持一个子菜单的展开
           collapse  是否水平折叠收起菜单（仅在 mode 为 vertical 时可用）
           collapse-transition 是否开启折叠动画
           router 是否使用 vue-router 的模式，启用该模式会在激活导航时以 index 作为 path 进行路由跳转 
           default-active 当前激活菜单的 index-->
          <el-menu
            background-color="rgb(0, 12, 59)"
            text-color="#fff"
            active-text-color="#409EFF"
            :unique-opened="true"
            :collapse="isCollapse"
            :collapse-transition="false"
            :router="true"
            :default-active="activePath"
          >
            <!-- 一级菜单 -->
            <!-- index是当下拉菜单只有相同的index才会下拉，所以每一个一级菜单得到index要不一样 -->
            <!-- index只接受字符串， 数值+ ''字符串 = 字符串 -->
            <el-submenu
              :index="item.id + ''"
              v-for="item in menulist"
              :key="item.id"
            >
              <!-- 一级菜单的模板区域 -->
              <template slot="title">
                <!-- 图标 -->
                <i :class="iconsObj[item.code]"></i>
                <!-- 文本 -->
                <span>{{ item.functionRsp.name }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item
                :index="'/' + subItem.path"
                v-for="subItem in item.children"
                :key="subItem.id"
                @click="saveNavState('/' + subItem.path)"
              >
                <!-- 二级菜单的模板区域 -->
                <template slot="title">
                  <!-- 图标 -->
                  <i class="el-icon-menu"></i>
                  <!-- 文本 -->
                  <span>{{ subItem.functionRsp.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
      </div>
      <!-- 右侧内容主体 -->
      <el-main>
        <div class="data-box1 box1-back">
          <i :class="[isCollapse ? 'topL1' : 'topL']"></i>
          <i class="topR"></i>
          <i class="bottomL"></i>
          <i class="bottomR"></i>
          <router-view></router-view>
        </div>
        <!-- 路由占位符 -->
      </el-main>
    </el-container>


    <!-- 版权信息 -->
    <el-dialog
  title="关于"
  :visible.sync="dialogVisible"
  width="30%">
  <h2>权限管理平台</h2><br>
  <span>版本信息: v1.0</span>
  <span slot="footer" class="dialog-footer">
    版权所有
  </span>
</el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      appCode: {
        appCode: 'Sso',
      },

      //左侧菜单数据
      menulist: [],
      //定义每个一级菜单的图标
      iconsObj: {
        SY: 'el-icon-s-home',
        yhgl: 'iconfont icon-user',
        qxgl: 'iconfont icon-tijikongjian',
        101: 'iconfont icon-shangpin',
        102: 'iconfont icon-danju',
        xtgl: 'iconfont icon-baobiao',
      },
      //是否折叠侧边栏
      isCollapse: false,
      //被激活的链接地址
      activePath: '',
      //获取当前的用户
      userInfo: {},
      //版权对话框显示
      dialogVisible:false
    }
  },

  created() {
    //赋值当前用户名
    this.realName = window.sessionStorage.getItem('realName')
    //获取菜单
    this.getMenuList()
    this.userInfo = JSON.parse(window.sessionStorage.getItem('user'))
    this.activePath =window.sessionStorage.getItem('activePath')
  },

  methods: {
    //退出登录
    async logout() {
      const { data: res } = await this.$http.get('oauth/logout', {
        params: { access_token: this.userInfo.access_token },
      })
      if (res.msg === '注销成功') {
        //清除token
        window.sessionStorage.clear()
        //跳转登录页面
        this.$router.push('/login')
      }
    },
    //获取所有的菜单
    async getMenuList() {
      //获取返回值的中data数据
      const { data: res } = await this.$http.get('admin/menu/getUserMenu', {
        params: this.appCode,
      })
      // console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.menulist = res.data.data
    },
    //点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    //保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    //下拉菜单触发事件
    handleCommand(command) {
      //退出
      if (command === 'logout') {
        this.logout()
        this.$message.success('退出成功')
      } else if (command === 'userCenter') {
        this.$router.push('/userCenter')
      } else if(command === 'copyrightInfo'){
        //打开对话框
        this.dialogVisible =true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.home-container {
  background-color: rgb(0, 12, 59);
  height: 100%;
}
.el-header {
  height: 82px;
  background: url(../assets/img/headbg.png) no-repeat;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    width: 60%;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    margin-left: 350px;
    font-family: 微软雅黑 !important;
    h3 {
      padding: 0 40px;
    }
  }
}

.el-aside {
  background-color: rgb(0, 12, 59);
  height: 100%;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: rgb(0, 12, 59);
}

.iconfont {
  margin-right: 10px;
}
.toggle-button {
  background-color: rgb(0, 12, 59);
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  //字体间距增大
  letter-spacing: 0.2em;
  //鼠标放上去变为小手
  cursor: pointer;
}

.data-box1 {
  box-shadow: inset 0 0 30px #07417a;
}
.topL {
  width: 20px;
  height: 20px;
  border-top-width: 2px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-left-width: 2px;
  border-left-color: #26c6f0;
  border-left-style: solid;
  position: absolute;
  top: 97px;
  left: 230px;
}

.topL1 {
  width: 20px;
  height: 20px;
  border-top-width: 2px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-left-width: 2px;
  border-left-color: #26c6f0;
  border-left-style: solid;
  position: absolute;
  top: 97px;
  left: 83px;
}

.topR {
  width: 20px;
  height: 20px;
  border-top-width: 2px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-right-width: 2px;
  border-right-color: #26c6f0;
  border-right-style: solid;
  position: absolute;
  top: 97px;
  right: 19px;
}

.aside {
  width: 210px;
  background-color: #000c3b;
  box-shadow: inset 0 0 10px #07417a;
}

.el-dropdown {
  color: rgb(255, 255, 255);
}
</style>