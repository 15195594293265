import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
//导入js
import './assets/js/jquery.js'
//导入字体图标
import './assets/fonts/iconfont.css'
//导入全局样式表
import './assets/css/global.css'
//导入axios
import axios from 'axios'
//用于树结构
import TreeTable from 'vue-table-with-tree-grid'




//配置请求的根路径
// axios.defaults.baseURL = 'http://127.0.0.1:8079/'
//axios请求拦截器
axios.interceptors.request.use(config =>{
  //将token 值写入header请求头中Authorization=token
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // console.log(config)

  //最后必须return config
  return config
},error =>{
  return Promise.reject(error)
})


//axios响应拦截器
axios.interceptors.response.use(function (response){
  // console.log(response.data.code)
  //如果当前token失效
  // if(response.data.code === 401){
  //    console.log(response.data.code)
  //   // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）
  //   window.sessionStorage.clear()
   
  // }
  return response

},function(error){
  // console.log(error.response.data)
  let data=error.response.data
  switch (data.code){
    case 401:
      //可以使用其他的提示框架，根据环境使用，MessageBox弹窗可执行promise操作，使在点击确定后执行某些操作
      if(data.msg=='无效的token'){
        Vue.prototype.$message({
          type: "error",
          message: "登录已过期，请重新登录"
       });
         //跳转登录页面
         router.replace({
          path: '/login' // 到登录页重新获取token
      })
      }
    }

  // console.log(error.response.status)
  return Promise.reject(error)
})


Vue.prototype.$http = axios
//全局注册组件
Vue.component('tree-table',TreeTable)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
