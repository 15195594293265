<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加用户</el-breadcrumb-item>
    </el-breadcrumb>




    <el-card>
      <div class="app-container">
        <!-- 输入表单
         ref重置表单时需要获取全局对象 rules 表单验证;  model 绑定input属性值 -->
        <el-form
          ref="addFormRef"
          :model="addForm"
          label-width="120px"
          :rules="addFormRules"
          :inline="false"
         
        >
          <el-form-item label="用户名" prop="userName" >
            <el-input v-model="addForm.userName" class="inputwidth"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="realName">
            <el-input v-model="addForm.realName"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="addForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="idCard">
            <el-input v-model="addForm.idCard"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="addForm.email"></el-input>
          </el-form-item>
          <el-form-item label="学校" prop="school">
            <el-input v-model="addForm.school"></el-input>
          </el-form-item>
          <el-form-item label="手机" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="是否禁用" prop="freeze">
            <el-switch v-model="addForm.freeze"></el-switch>
          </el-form-item>
          <el-form-item label="用户排序">
            <el-input-number v-model="addForm.sort" :min="0" />
          </el-form-item>
          <!-- 讲师头像：TODO -->
          <el-form-item label="用户头像">
            <!-- el-upload已经封装了ajax
        show-file-list是否显示文件列表
        on-success文件上传成功回调函数
         on-error文件上传失败回调函数（http失败）
        before-upload文件上传之前的回调（文件的大小校验）-->
            <!-- <el-upload
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload"
              class="avatar-uploader"
              action="http://localhost:8120/admin/oss/file/upload?module=avatar"
            >
              <img v-if="teacher.avatar" :src="teacher.avatar" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload> -->
          </el-form-item>
          <!--  disabled按钮禁用 -->
          <el-form-item>
            <el-button
              :disabled="saveBtnDisabled"
              type="primary"
              @click="addUser()"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
     //自定义验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      //验证通过
      if (regEmail.test(value)) {
        //合法的邮箱
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    //自定义验证手机号的规则
    var checkMoblie = (rule, value, cb) => {
      //验证手机号的正则表达式
      // const regMoblie = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      const regMoblie = /^1[1356789]\d{9}$/
      //验证通过
      if (regMoblie.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      //添加用户的表单数据
      addForm: {
        userName: '',
        password: '',
        email: '',
        phone: '',
      },

      //添加表单的验证规则对象
      addFormRules: {
        userName: [
          //trigger: 'blur'当鼠标离开输入框时触发
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        email: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        phone: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入手机', trigger: 'blur' },
          { validator: checkMoblie, trigger: 'blur' },
        ],
        idCard: [
          //validator指定自定义的校验规则
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{17}(\d|X|x)$)/,//长度或格式校验
            message:"请输入正确的身份证",
            trigger: 'blur' },
        ]
      },
      saveBtnDisabled: false, // 保存按钮是否禁用，防止表单重复提交
    }
  },


  methods: {

    //点击按钮，添加新用户
    addUser() {
      //validate()对整个表单进行校验的方法，参数为一个回调函数。该回调函数会在校验结束后被调用，
      //并传入两个参数：是否校验成功和未通过校验的字段。若不传入回调函数，则会返回一个 promise
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        //可以发起添加用户的网络请求
        const { data: res } = await this.$http.post(
          '/admin/user/addUser',
          this.addForm
        )

        if (res.code != 200) {
          this.$message.error(res.msg)
        }
        this.$message.success('添加用户成功！')
        // 路由跳转到列表查询
        this.$router.push({path:'/users'})
      })
    },
    

    // // 文件上传成功
    // handleAvatarSuccess(response) {
    //   if (response.success) {
    //     console.log(response)
    //     this.teacher.avatar = response.data.url
    //     // 强制重新渲染
    //     this.$forceUpdate()
    //   } else {
    //     this.$message.error('上传头像失败!（非20000）') // 弹出错误消息
    //   }
    // },

    // // 文件上传失败（http）
    // handleAvatarError() {
    //   this.$message.error('上传头像失败!（http失败）') // 弹出错误消息
    // },
    // // 文件上传前的校验
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg' // Mcon's
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!') // 弹出错误消息
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
